function generateParams(field, operator, value, separator) {
    let str = "";
    let params = {};
    if (Array.isArray(value) && value.length !== 0)
        str = value.join(separator);
    else if (typeof value === "string")
        str = value.toString();
    const queryField = field + (operator === null ? "" : `__${operator}`);
    if (str !== "")
        params = { [queryField]: str };
    return params;
}
export default generateParams;

import { request } from "./request";
import generateParams from "../utils/generate-params";
import { Group } from "../models/Group";
export function fetchLocations(params) {
    return request.get("/office/mrus", { params }).then(res => res.results);
}
export function fetchRegions(params) {
    return request.get("/office/mrus", { params }).then(res => res.results);
}
export function fetchGroups(locationIds = [], params) {
    const mru_param = generateParams("mru", "in", locationIds, ",");
    params = { ...params, ...mru_param };
    return request.get("/office/groups", { params }).then(res => res.results);
}
export async function fetchGroups2(regionIds = [], params) {
    const mru_param = generateParams("mru", "in", regionIds, ",");
    params = { ...params, ...mru_param };
    const apiGroups = await request.get("/office/groups", { params }).then(res => res.results);
    return apiGroups.map(g => new Group(g));
}
export function fetchDepartments(groupIds = [], params) {
    const group_param = generateParams("group", "in", groupIds, ",");
    params = { ...params, ...group_param };
    return request.get("/office/locations", { params }).then(res => res.results);
}
export function fetchPositions(departmentsIds) {
    const params = generateParams("location", "in", departmentsIds, ",");
    return request.get("/office/positions", { params }).then(res => res.results);
}
export function fetchRescuers(departmentsIds) {
    return fetchPositions(departmentsIds).then(positions => positions.map(p => p.user));
}
export function fetchRescuers2(departmentsIds = []) {
    const params = generateParams("location", "in", departmentsIds, ",");
    return request
        .get("/office/rescuers", { params: { ...params, limit: 1000 } })
        .then(res => res.results);
}
export function fetchRescuersByQuery(query) {
    const params = { search: query };
    return request.get("/office/rescuers", { params }).then(res => res.results);
}

import { request } from "./request";
import generateParams from "../utils/generate-params";
import { Vehicle } from "../models/Vehicle";
export async function fetchVehicles(departmentsIds = []) {
    const params = generateParams("location", "in", departmentsIds, ",");
    const res = await request.get("/vehicles", { params }).then(res => res.results);
    return res.map(v => new Vehicle(v));
}
export function fetchVehicles2(departmentsIds = []) {
    const params = generateParams("location", "in", departmentsIds, ",");
    return request.get("/vehicles", { params }).then(res => res.results);
}
export async function fetchVehiclesByQuery(query) {
    const params = { search: query };
    const res = await request.get("/vehicles", { params }).then(res => res.results);
    return res.map(v => new Vehicle(v));
}
